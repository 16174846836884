import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import PricingCards from '../components/pricing-cards';
import Feature from '../components/feature';

import otherFeatureVisualContent from '../images/other-features.svg';


const pricingCards = [
  {
    title: 'Education Student',
    text: 'Sign up with your .edu email and get 75% off ',
    price: '4',
    priceUnit: '$',
    priceAdd: '/month paid annually',
    promo: {
      firstLine: '-75%',
      secondLine: 'off',
    },
    description: {
      baseline: 'This is what you want',
      title: 'This is what you want',
      text: 'Get the full Ludus experience like anybody else. Just cheaper.',
      linkLabel: 'What do I get?',
      linkUrl: '#',
      buttonLabel: 'Try Ludus for free',
      buttonUrl: '#',
    }
  },
  {
    title: 'Education School',
    text: 'Receive a number of accounts to use for a flat price - for your organisation',
    priceText: 'Customized price',
    description: {
      baseline: 'This is what you want',
      title: 'This is what you want',
      text: 'Get the full Ludus experience like anybody else. Just cheaper.',
      linkLabel: 'Need more info?',
      linkUrl: '#',
      buttonLabel: 'Contact us',
      buttonUrl: '#',
    }
  }
];

const Startups = () => {
  return (
    <Layout
      socialDark={true}
      currentPage='startups'
    >
      <SEO title="Startups" />

      <Section>
        <ContentHero
          visual="education-visual.jpg"
          visualAlt="Startups"
          title="Startups"
          text="<p>...</p>"
        />
      </Section>

      <Section>
        <PricingCards cards={pricingCards} />
      </Section>
      
      <Section>
        <div style={{
          position: 'absolute',
          zIndex: '1',
          width: '39%',
          paddingBottom: '61%',
          bottom: '0',
          left: '0',
          background: `transparent url(${otherFeatureVisualContent}) no-repeat 0 50%`,
          backgroundSize: 'contain',
        }} />

        <Feature 
          reverse={true}
          extraspace={true}
          title="And many other stuff..."
          text="Just a list of everything you might use when working with ludus. And yes, it's a big list. Very big. Big."
          url="#"
          link="See everything"
        />
      </Section>

      
    </Layout>
  )
};

export default Startups
